import React, { Component } from 'react';
import { Redirect } from '@reach/router';

// import { App } from 'modules/app';
// import { MainNavigation } from 'modules/navigation';
// import { HomePage } from 'modules/homepage';

class Root extends Component {
  render() {
    return <Redirect to="/lab" />;

    // return (
    //   <App>
    //     <MainNavigation />
    //     <HomePage />
    //   </App>
    // );
  }
}

export default Root;
